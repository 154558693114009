<template>
  <div class="event-status-bar" data-t="event-status-bar" :class="classes">
    <StIcon
      :name="sportIcon"
      :size="sportIconSize"
      :data-t="`sport-icon-${sportIcon}`"
      class="sport-icon"
    />
    <template v-if="isLive">
      <StIcon
        name="live"
        :size="16"
        class="live-indicator"
        data-t="live-indicator"
      />
      <div class="live-clock">
        <span data-t="status">{{ matchTiming }}</span>
        <span v-if="isShownClock" data-t="match-time">{{ clock }}</span>
      </div>
    </template>
    <template v-else>
      <span class="scheduled" data-t="scheduled">
        {{ startDate }} {{ startTime }}
      </span>
    </template>
    <StIcon v-if="isLMTAvailable" name="lmt" :size="16" />
    <div v-if="withScores && isLive" class="live-score">
      {{ event.match?.stats?.home_score }} -
      {{ event.match?.stats?.away_score }}
    </div>
  </div>
</template>

<script setup lang="ts">
import type { TSportEvent } from 'markets-store'
import { getSportIcon } from 'markets-store/helpers/getSportIcon'
import type { IconName } from '@st/ui/components/StIcon/types'
import { useLiveClock } from '../../composables'

const props = withDefaults(
  defineProps<{
    event: TSportEvent
    size?: 'm' | 's'
    withScores?: boolean
    compact?: boolean
  }>(),
  {
    size: 'm',
    compact: false,
  },
)

const { event, size } = toRefs(props)

const classes = computed(() => [`size-${size.value}`])

const { matchTiming, clock, isShownClock } = useLiveClock(event, {
  onlyMinutes: true,
})

const sportIcon = computed(() => getSportIcon(event.value) as IconName)

const isLive = computed(() => !!event.value.status)

const { startDate, startTime } = useStartDate(event)

const sportIconSize = computed(() => (size.value === 's' ? 14 : 16))

// TODO add real condition
const isLMTAvailable = ref(false)
</script>

<style scoped>
.event-status-bar {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;

  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);

  &.size-s {
    font: var(--desktop-text-xxs-medium);
  }

  &.size-m {
    font: var(--desktop-text-sm-medium);
  }
}

.live-clock {
  display: flex;
  gap: var(--spacing-025);
}

.live-indicator {
  color: var(--text-link);
}

.live-score {
  font: var(--desktop-text-xs-semibold);
  color: var(--text-link);
}

.sport-icon {
  color: var(--text-primary);
}

.scheduled {
  white-space: nowrap;
}
</style>
